<template>
  <!--features image-->
  <div>
    <div class=" ">
      <div class="img-container">
        <div class="img-overlay"></div>
        <img class="first-slide img-fluid" src="@/assets/book6.jpg" />
        <div class="bg-text position-absolute top-5 start-5">
          <h3 class="text-center mt-5 h1 text-light text-head pt-5">
            <b>FEATURES</b>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="accounding pt-5">
      <b class="pt-5">ACCOUNTING</b>
    </div>
    <p class="acc_text pt-2">
      Kenwyn Books will allow your accounting department to integrate with your
      HR and inventory functions. You can now get a full overview of your
      company operations.
    </p>
    <div class="row pt-5 pb-5">
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Invoicing</b></h5>
              <p class="card-text">Create invoices with ease.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-suitcase fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Quotation</b></h5>
              <p class="card-text">
                Easily send cost estimates to customer or suppliers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-thumbs-up fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Sales and Purchase Order</b></h5>
              <p class="card-text">
                Generate sales and purchase orders for customers or suppliers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-sticky-note fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Credit Note</b></h5>
              <p class="card-text">
                Issue a credit note to your customers or suppliers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-truck fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Inventory Management</b></h5>
              <p class="card-text">Track your stock quantity and movement.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-book fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Financial Reports</b></h5>
              <p class="card-text">
                Understand your company’s financial health via reports such as
                Profit & Loss and Balance Sheet.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-address-book fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>GST Computation</b></h5>
              <p class="card-text">
                Compute GST accurately with the transactions input from the
                accounting suite.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-laptop fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Audit Trail</b></h5>
              <p class="card-text">
                Trace actions and changes made to the system transactions to
                ensure compliance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="accounding pt-5">
      <b>HUMAN RESOURCES</b>
    </div>
    <p class="hr_text pt-2">
      People are at the heart of every business. Recruit, retain and reward your
      staff effectively. From leave management to payroll, Kenwyn Books gives
      you the tools to manage your employees and their needs so they stay
      satisfied and productive.
    </p>
    <div class="row pt-5 pb-5">
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-building fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Leave Management</b></h5>
              <p class="card-text">
                Track leave and manage manpower for the entire office.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-credit-card fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Itemised Payslips</b></h5>
              <p class="card-text">
                Keep up with the law with itemised payslips.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-exclamation-circle fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>CPF Computation</b></h5>
              <p class="card-text">
                Compute and file CPF with zero errors. Never get fined again.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 15rem">
            <div class="card-body text-center">
              <i class="fa fa-check-square fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Attendance Management</b></h5>
              <p class="card-text">
                Precise attendance logging whether it's for retail or events
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="accounding pt-5">
      <b>SECRETARY</b>
    </div>
    <p class="hr_text pt-2">Bridging Tech and Corporate Compliance</p>
    <div class="row pt-5 pb-5">
      <div class="col-md-4 col-sm-12 col-lg-4 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 18rem">
            <div class="card-body text-center">
              <i class="fa fa-user-circle fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2">
                <b>Experienced Corporate Secretary</b>
              </h5>
              <p class="card-text">
                Acquire a committed digital corporate secretary with over a
                decade of experience right from the start. Connect effortlessly
                through live chat and receive a response within 24 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-lg-4 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 18rem">
            <div class="card-body text-center">
              <i class="fas fa-shield-alt fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Ensuring Your Compliance</b></h5>
              <p class="card-text">
                We ensure the proper maintenance and timely updates of your
                statutory registers, ensuring ongoing compliance with regulatory
                authorities as your company progresses.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-lg-4 pt-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 18rem">
            <div class="card-body text-center">
              <i class="fas fa-lock fa-4x" aria-hidden="true"></i>
              <h5 class="card-title pt-2"><b>Secure your documents</b></h5>
              <p class="card-text">
                Eliminate concerns about hunting for misplaced documents. Your
                files are safely stored within your account, simplifying
                organization and providing convenient access.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
h1 {
  padding-top: 200px;
}

.container-fluid {
  background: rgb(240, 238, 238);
  padding-top: 100px;
}

.acc_pic {
  width: 250px;
  height: 250px;
  padding-left: 25px;
}
.accounding {
  font-size: 30px;
  color: rgb(8, 147, 29);
  text-align: center;
  font-size: 40px;
}
.acc_text {
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 17px;
}

.card-img {
  height: 70px;
  width: 70px;
}

.img-container {
  max-width: 100%;
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}

.first-slide {
  width: 100%;
  max-height: 500px;
  background-size: cover;
  z-index: 0;
}

/* .first-slide {
  width: 1600px;
  max-height: 500px;
  background-size: cover;
} */
.head {
  font-size: 50px;
  text-align: center;
}
.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
/* .img-gradient {
  position: relative;
  display: inline-block;
} */

/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
/* .img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;

  background: linear-gradient(
    to bottom,
    rgba(2, 1, 23, 0.619) 100%,
    rgba(2, 1, 23, 0.619) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 );
} */
/* .img-gradient img {
  display: block;
} */
h5 {
  text-align: center;
  color: rgb(6, 160, 6);
}
.card {
  text-align: center;
  background-color: transparent;
  border: transparent;
}
.row {
  padding-top: 20px;
}
.btn-demo {
  background-color: rgb(252, 250, 250);
  color: rgb(26, 6, 112);
  border-color: rgb(26, 6, 112);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}
.human-res {
  font-size: 30px;
  color: rgb(8, 147, 29);
  text-align: center;
  font-size: 40px;
}
.container-fluid-hr {
  background: rgb(255, 255, 255);
  padding-top: 100px;
  padding-bottom: 150px;
}
.hr_text {
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 17px;
}
.aboutPara {
  color: rgba(248, 248, 255, 0.674);
  font-size: 11px;
  letter-spacing: 1px;
}
@media only screen and (max-width: 600px) {
  .first-slide {
    max-width: 100%;
    height: 700px;
  }
  .head {
    font-size: 30px;
  }
}
@media only screen and (max-width: 300px) {
  .first-slide {
    max-width: 100%;
    height: 400px;
  }
  .head {
    font-size: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .first-slide {
    max-width: 100%;
    height: 900px;
  }
  .head {
    font-size: 30px;
  }
}
</style>
