<template>
  <!--service image-->
  <div>
    <div class=" ">
      <div class="img-container">
        <div class="img-overlay"></div>
        <img class="first-slide img-fluid" src="@/assets/book6.jpg" />
        <div class="bg-text position-absolute top-5 start-5">
          <h3 class="text-center mt-5 h1 text-light text-head pt-5">
            <b>CONTACT US</b>
          </h3>
        </div>
      </div>
    </div>
  </div>

  <!--contact us page-->
  <div class="container">
    <div class="m-3">
      <div class="row pt-5 pl-3">
        <div class="col-md-6 col-sm-12 col-lg-6">
          <h5 class="pl-2"><b>KEEP IN TOUCH WITH US</b></h5>
          <div class="text-secondary">
            <p class="pt-3 pl-2">
              Get in touch with us! Have questions or feedback? We're here to
              help. Reach out using the information provided below, and we'll
              get back to you as soon as possible.
            </p>
          </div>
          <div class="pl-2 text-secondary">
            <p class="pt-3">
              <i
                class="fa fa-mobile fa-1x"
                aria-hidden="true"
                style="font-size: 34px"
                >&nbsp;&nbsp;</i
              ><span>+65-97223997</span>
            </p>
            <hr />

            <p class="pt-3">
              <i
                class="fa fa-envelope fa-1x"
                aria-hidden="true"
                style="font-size: 34px"
                >&nbsp;&nbsp;</i
              ><span>info@kenwynaccounting.com</span>
            </p>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6 pr-5">
          <h5 class="Link"><b>SEND US YOUR MESSAGE</b></h5>
          <div class="pl-2 text-secondary">
            <div class="mb-3 pt-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Name</label
              >
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Email</label
              >
              <input
                type="email"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Subject</label
              >
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
              />
            </div>
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label"
                >Message</label
              >
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
            <div class="pt-4">
              <button type="button" class="btn btn-outline-secondary">
                <b> SEND MESSAGE</b>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--contact us page-->
      <!--map added-->
      <!-- <div class="pt-5">
      <iframe
        allowfullscreen
        aria-hidden="false"
        frameborder="0"
        height="500"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1972.5627120596755!2d81.21546944699567!3d8.583937744181867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afbbce7b203ce3b%3A0x87f4b04584a9ae7a!2sSmak%20Intel%20Solutions!5e0!3m2!1sen!2slk!4v1607336021812!5m2!1sen!2slk"
        style="border: 0; display: block"
        tabindex="0"
        width="100%"
      />
    </div> -->
      <!--map added-->
      <!--newsletter page-->
    </div>
  </div>
  <div class="container-fluid">
    <div class="row pb-5 pr-5 pl-2 rowColor">
      <div class="col-md-4 col-sm-4 col-lg-4 pt-5 rowClr">
        <h5 class="text-light"><b>GET OUR NEWSLETTER</b></h5>
      </div>
      <div class="col-md-6 col-sm-6 col-lg-6 rowClr">
        <div class="pt-5">
          <input
            type="text"
            class="form-control"
            placeholder="Your Email Address"
          />
        </div>
      </div>
      <div class="col-md-2 col-sm-2 col-lg-2 pl-3 rowClr">
        <div class="pt-5">
          <button type="button" class="btn btn-outline-light">SUBSCRIBE</button>
        </div>
      </div>
    </div>
  </div>

  <!--newsletter page-->
</template>
<style scoped>
.first-slide {
  width: 100%;
  max-height: 500px;
  background-size: cover;
  z-index: 0;
}

.img-container {
  max-width: 100%;
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}
/* .first-slide {
  width: 1600px;
  max-height: 500px;
  background-size: cover;
} */
.rowColor {
  background-color: green;
}

.head {
  font-size: 50px;

  text-align: center;
}
.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

/* .img-gradient {
  position: relative;
  display: inline-block;
} */

/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
/* .img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;

  background: linear-gradient(
    to bottom,
    rgba(2, 1, 23, 0.619) 100%,
    rgba(2, 1, 23, 0.619) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 );
} */
/* .img-gradient img {
  display: block;
} */
@media only screen and (max-width: 600px) {
  .first-slide {
    max-width: 100%;
    height: 700px;
  }
  .head {
    font-size: 30px;
  }
}
@media only screen and (max-width: 300px) {
  .first-slide {
    max-width: 100%;
    height: 400px;
  }
  .head {
    font-size: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .first-slide {
    max-width: 100%;
    height: 900px;
  }
  .head {
    font-size: 30px;
  }
}
</style>
