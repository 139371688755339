<template>
  <!--Main Header-->
  <!-- <div class="img-overlay">
    <div id="main-header">
      <div class="container">
        <div class="pt-3 text-center bg-text fs-3">
          <h><i class="fas fa-map-marker-alt"></i>ho</h>
          <h3 class="h1 text-light text-head">
            <b>ABOUT US</b>
          </h3>
        </div>
      </div>
    </div>
  </div> -->
  <section class="hero-image">
    <div class="overlay">
      <div class="hero-text">
        <h1>ABOUT US</h1>
        <p></p>
        <!-- <a href="#" class="btn btn-primary">Get Started</a> -->
      </div>
    </div>
  </section>

  <div class="container">
    <div class="row pt-5 first-slide">
      <div class="col-md-6 col-sm-12 col-lg-6 maintext">
        <p>
          Welcome to Kenwyn Books, where innovation meets precision in the world
          of financial management. Established with a vision to revolutionize
          the way businesses handle their finances, we are a dynamic and
          forward-thinking company dedicated to delivering cutting-edge
          accounting solutions.
        </p>
        <p>
          At Kenwyn Books, we understand the challenges that businesses face in
          managing their financial data efficiently. With a passion for
          simplifying complex financial processes, we have developed a
          state-of-the-art accounting system that empowers organizations of all
          sizes to streamline their financial operations, enhance accuracy, and
          make informed decisions.
        </p>
        <p>
          Our team of skilled professionals brings together expertise in
          finance, technology, and user experience design to create a robust and
          user-friendly accounting system. We pride ourselves on staying ahead
          of industry trends and continually evolving our solutions to meet the
          ever-changing needs of our clients.
        </p>
        <p>
          What sets us apart is our commitment to providing more than just a
          software solution. We strive to build lasting partnerships with our
          clients, offering unparalleled support and guidance throughout their
          financial journey. Whether you're a small startup or a
          well-established enterprise, our accounting system is tailored to fit
          your unique requirements, ensuring scalability and adaptability as
          your business grows.
        </p>
        <p>
          Transparency, integrity, and innovation are the cornerstones of our
          company culture. We believe in empowering businesses to focus on what
          they do best by simplifying their financial processes. With Kenwyn
          Books, you can trust that your financial data is in capable hands,
          allowing you to drive your business forward with confidence.
        </p>
        <p>
          Join us on the journey towards financial efficiency and success.
          Explore the possibilities with Kenwyn Books - where your success is
          our business.
        </p>
      </div>
      <div class="col-md-6 col-sm-12 col-lg-6">
        <img src="@/assets/about.webp" class="img-fluid" />
      </div>
    </div>
  </div>
  <div class="row pt-5 first-slide">
    
  </div>
</template>

<style scoped>
.hero-image {
  height: 100vh;
  background-size: cover;
  width: 100%;
  height: 430px;
  background-position: center;
  margin-top: 72px;
  background-image: url("@/assets/ABOUT-US.webp");
}
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-text {
  color: #fff;
  text-align: center;
}

.maintext {
  text-align: justify;
}
/* #main-header {
  background-image: url(@/assets/ABOUT-US.webp);
  background-size: cover;
  width: 100%;
  height: 430px;
  align-items: center;
  display: flex;
  margin-top: 72px;
  color: rgb(255, 255, 255);
} */
/* .img-overlay {
  background-color: rgb(163, 0, 0);
  opacity: 0.7;
  z-index: 1;
} */

/* .inner {
  padding: 10px;
  transition: 0.5s ease-in-out;
} */
/* .inner:hover {
  transform: scale(1.1);
} */

/* .card :hover {
  opacity: 0.7;
} */

/* .head {
  font-size: 30px;
} */
</style>
